import { useForm, getFormProps } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { invariantResponse } from '@epic-web/invariant'
import {
	json,
	type AppLoadContext,
	type LoaderFunctionArgs,
	type ActionFunctionArgs,
	type HeadersFunction,
	type LinksFunction,
	type MetaFunction,
} from '@remix-run/node'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useFetcher,
	useFetchers,
	useLoaderData,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { z } from 'zod'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import Footer from './components/footer.tsx'
// import Header from './components/header.tsx'
import { EpicProgress } from './components/progress-bar.tsx'
import { Icon, href as iconsHref } from './components/ui/icon.tsx'
import { EpicToaster } from './components/ui/sonner.tsx'
import tailwindStyleSheetUrl from './styles/app.css?url'
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx'
import { getDomainUrl } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { useRequestInfo } from './utils/request-info.ts'
import { type Theme, setTheme, getTheme } from './utils/theme.server.ts'

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		// Preload CSS as a resource to avoid render blocking
		{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
		{
			rel: 'alternate icon',
			type: 'image/png',
			href: '/favicons/favicon-32x32.png',
		},
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		//These should match the css preloads above to avoid css as render blocking resource
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'Ramona Ridgewell' : 'Error | Ramona Ridgewell' },
		{ name: 'Ramona Ridgewell', content: `Creator of vampires` },
	]
}

// Extend the AppLoadContext to include cspNonce
interface ExtendedAppLoadContext extends AppLoadContext {
  cspNonce?: string;
}

type LoaderData = {
  requestInfo: {
    hints: ReturnType<typeof getHints>;
    origin: string;
    path: string;
    userPrefs: {
      theme: ReturnType<typeof getTheme>;
    };
  };
  cspNonce: string | undefined;
};

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
	// Cast context to our extended type
	const extendedContext = context as ExtendedAppLoadContext;

  const data: LoaderData = {
    requestInfo: {
      hints: getHints(request),
      origin: getDomainUrl(request),
      path: new URL(request.url).pathname,
      userPrefs: {
        theme: getTheme(request)
      },
    },
    cspNonce: extendedContext.cspNonce,
  }

	const headers = {
    headers: {
      // "Cache-Control": "private, max-age=3600",
      "Cache-Control": "no-store, max-age=0",
    },
	}

	return json(data, headers);
};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

const ThemeFormSchema = z.object({
	theme: z.enum(['system', 'light', 'dark']),
})

export const  action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: ThemeFormSchema,
	})

	invariantResponse(submission.status === 'success', 'Invalid theme received')

	const { theme } = submission.value

	const responseInit = {
		headers: { 'set-cookie': setTheme(theme) },
	}
	return json({ result: submission.reply() }, responseInit)
}

type DocumentProps = {
  nonce?: string;
  theme?: Theme;
  children?: React.ReactNode;
	env?: Record<string, string>;
	allowIndexing?: boolean;
};

const Document:React.FC<DocumentProps> = ({
	children,
	nonce,
	theme = 'light',
	env = {},
	allowIndexing = true,
}: {
	children?: React.ReactNode
	nonce?: string
	theme?: Theme
	env?: Record<string, string>
	allowIndexing?: boolean
}) => {
	return (
		<html lang="en" className={`${theme} h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

const App = () => {
	const data = useLoaderData<typeof loader>()
	const theme = useTheme()

	return (
		<Document
			nonce={data.cspNonce}
			theme={theme}
		>
			<div className="flex min-h-screen flex-col justify-between overflow-y-auto">
				{/* <Header /> */}

				<main className="flex-1 max-h-full max-w-full">
					<Outlet />
				</main>
				
				<Footer theme={getThemeSwitch(data.requestInfo.userPrefs.theme)} />
			</div>
			<EpicToaster closeButton position="top-center" theme={theme} />
			<EpicProgress />
		</Document>
	)
}

const getThemeSwitch = (theme: Theme | null | undefined) => {
	return (<ThemeSwitch userPreference={theme} />)
}

const AppWithProviders = () => {
	return (
			<App />
	)
}

export default withSentry(AppWithProviders)

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export const useTheme = () => {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticThemeMode()
	if (optimisticMode) {
		return optimisticMode === 'system' ? hints.theme : optimisticMode
	}
	return requestInfo.userPrefs.theme ?? hints.theme
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export const useOptimisticThemeMode = () => {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find(f => f.formAction === '/')

	if (themeFetcher && themeFetcher.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})

		if (submission.status === 'success') {
			return submission.value.theme
		}
	}
}

const ThemeSwitch = ({ userPreference }: { userPreference?: Theme | null }) => {
	const fetcher = useFetcher<typeof action>()

	const [form] = useForm({
		id: 'theme-switch',
		lastResult: fetcher.data?.result,
	})

	const optimisticMode = useOptimisticThemeMode()
	const mode = optimisticMode ?? userPreference ?? 'system'
	const nextMode =
		mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system'
	const modeLabel = {
		light: (
			<Icon name="sun" aria-label='light mode sun'>
				<span className="sr-only">Light</span>
			</Icon>
		),
		dark: (
			<Icon name="moon" aria-label='light mode moon'>
				<span className="sr-only">Dark</span>
			</Icon>
		),
		system: (
			<Icon name="laptop" aria-label='dark/light mode system monitor'>
				<span className="sr-only">System</span>
			</Icon>
		),
	}

	return (
		<fetcher.Form method="POST" {...getFormProps(form)}>
			<input type="hidden" name="theme" value={nextMode} />
			<div className="flex gap-2">
				<button
					type="submit"
					className="flex h-8 w-8 cursor-pointer items-center justify-center"
				>
					{modeLabel[mode]}
				</button>
			</div>
		</fetcher.Form>
	)
}

export const ErrorBoundary =() => {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
